import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import axios from 'axios'
import { Row, Col, Gap, Text, Section, Title, Button, VH, SEO, TextField } from '../components'
import { up } from '../lib/styles'
import Link from '../components/Link'
import links from '../lib/links'

const Box = styled.div`
  background: #e4d4be;
  display: block;
  padding: 40px 28px;
  width: 100%;

  ${up('mobile')} {
    padding: 100px 50px 100px 76px;
  }
`

const BottomGap = styled.div`
  display: block;
  height: calc(min(100vw, 1920px) / 2.5);

  /* ${up('mobile')} {
    height: 0;
  } */
`

const PageWrapper = styled.div`
  position: relative;
  min-height: calc(min(100vw, 1920px) * (2362 / 1920));
`

const BgImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 107px;
  left: 0;
  height: calc(100% - 107px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* display: flex;
  justify-content: flex-end;
  img {
    width: 100% !important;
  } */
`

const BText = (props) => <Text black fontWeight="400" {...props} />
const BLinkBlank = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`
const BLink = styled(Link)`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const ThanksText = BText

const ArrowDesktop = () => (
  <svg width="112" height="44" viewBox="0 0 112 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="80" y="16" width="12" height="80" transform="rotate(90 80 16)" fill="#E6D3B8" />
    <path d="M112 22L79 41.0526V2.94744L112 22Z" fill="#E6D3B8" />
  </svg>
)

const ArrowMobile = () => (
  <svg width="44" height="112" viewBox="0 0 44 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="28" y="80" width="12" height="80" transform="rotate(180 28 80)" fill="#E6D3B8" />
    <path d="M22 112L2.94744 79H41.0526L22 112Z" fill="#E6D3B8" />
  </svg>
)

const Arrow = () => (
  <>
    <VH showAfter={BP1}>
      <ArrowDesktop />
    </VH>
    <VH showBelow={BP1 - 1}>
      <ArrowMobile />
    </VH>
  </>
)

const BGap = () => <Gap gap="70px" mobileGap="42px" />
const BGapSmall = () => <Gap gap="40px" mobileGap="24px" />

const BP1 = 1024

const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

class Invest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSent: false,
      email: '',
      errorMessage: '',
    }
  }

  onEmailSend = async () => {
    const { email } = this.state
    if (!email) return this.setState({ errorMessage: 'Email is required' })
    if (!validateEmail(email)) return this.setState({ errorMessage: 'Email is not valid' })
    this.setState({ isSent: true })

    try {
      await axios.post('/api/v1/investor-contact', {
        email: email.trim(),
      })
      this.setState({ email: '', errorMessage: '' })
    } catch (error) {
      this.setState({ isSent: false, errorMessage: '' })
      setTimeout(
        // call after ui update
        () => alert('Sending failed'),
        1,
      )
    }
  }

  render() {
    const { email, isSent, errorMessage } = this.state

    const {
      location: { pathname },
      data: { bottomImage },
    } = this.props
    return (
      <PageWrapper>
        <SEO
          title="Invest | Zuri"
          description="We are developing a modern private aircraft with vertical takeoff and landing. This will allow you to affordably travel from city to city at distances of up to 700 km apart."
          pathname={pathname}
        />
        <Gap.Top />
        <Section background="white">
          <Gap gap="70px" mobileGap="60px" />
          <Row maxWidth="1300px" margin="auto">
            <Title.Big innerAs="h2" style={{ textTransform: 'uppercase' }}>
              Investment History
            </Title.Big>
          </Row>
          <Gap gap="40px" />

          <Row alignItems="stretch" responsive bp={BP1}>
            <Box>
              <BText upperCase>Pre-seed convertible notes</BText>
              <BGap />
              <BText>€1,100,000</BText>
              <BGap />
              <BText>
                <BLinkBlank href="https://palefirecapital.com/en/">Pale Fire Capital</BLinkBlank>
                <> - great VC</>
              </BText>
              <BText>
                <BLinkBlank href="https://www.kiwi.com/en">Kiwi.com</BLinkBlank>
                <> - large airline ticket platform</>
              </BText>
              <BText>
                <BLinkBlank href="https://www.keenswh.com/">Keen SWH</BLinkBlank>
                <> - authors of Space Engineers</>
              </BText>
            </Box>
            <Col justifyContent="center" alignItems="center">
              <Arrow />
            </Col>

            <Box>
              <BText upperCase>Seed round</BText>
              <BGap />
              <BText>€1,300,000</BText>
              <BGap />
              <BText>
                <BLinkBlank href="http://www.jaroslavbeck.com/">Jaroslav Beck</BLinkBlank>
                <> - cofounder of </>
                <BLinkBlank href="https://beatsaber.com/">Beat Games</BLinkBlank>
              </BText>
              <BText>
                <>J. Habermann & O. Bartoš - partners of </>
                <BLinkBlank href="https://www.credoventures.com/">Credo Ventures</BLinkBlank>
              </BText>
              <BText>
                <>Rasty Turek - founder and CEO of </>
                <BLinkBlank href="https://pex.com/">Pex.com</BLinkBlank>
              </BText>
              <BText>
                <>And </>
                <BLink to={links.STORY}>more</BLink>
                <> angel investors and entrepreneurs</>
              </BText>
              <Gap gap="100px" mobileGap="0" bp={BP1} />
            </Box>
          </Row>

          <Gap gap="70px" />
          <Row maxWidth="1300px" margin="auto">
            <Title.Big innerAs="h2" style={{ textTransform: 'uppercase' }}>
              Investment Opportunity
            </Title.Big>
          </Row>
          <Gap gap="40px" />

          <Row alignItems="stretch" responsive bp={BP1}>
            <Box>
              <BText style={{ fontStyle: 'italic' }}>Opened now</BText>
              <BGap />
              <BText upperCase>Pre-series A convertible notes</BText>
              <BGapSmall />
              <BText>Up to €1,500,000</BText>
              <BText>25-35 % discount to series A valuation</BText>
              <BText>€1.3M received already</BText>
              <Gap gap="100px" mobileGap="60px" />
              {!isSent && (
                <>
                  <BText>Get more information:</BText>
                  <Gap gap="20px" mobileGap="12px" />

                  <Row responsive>
                    <TextField
                      width="100%"
                      placeholder="Your e-mail"
                      value={email}
                      onChange={({ target }) => this.setState({ email: target.value })}
                      required
                      hasError={!!errorMessage}
                      error={errorMessage}
                      onBlur={() => this.setState({ errorMessage: '' })}
                    />
                    <Gap gap="8px" />
                    <Button.SubmitInvestForm onClick={this.onEmailSend}>
                      SUBMIT
                    </Button.SubmitInvestForm>
                  </Row>
                  <Gap gap="20px" mobileGap="12px" />

                  <BText>Leave us your contact and we’ll send the information package.</BText>
                </>
              )}
              {isSent && <ThanksText>Thank you, we will contact you soon.</ThanksText>}
            </Box>

            <Col justifyContent="center" alignItems="center">
              <Arrow />
            </Col>

            <Box>
              <Gap gap="100px" mobileGap="0" bp={BP1} />
              <BText upperCase>Series A</BText>
              <BGapSmall />
              <BText>Up to €15,000,000</BText>
              <BGapSmall />
              <BText>Planned for H2/2024</BText>
            </Box>
          </Row>

          <Gap gap="120px" mobileGap="70px" />
          <BottomGap />
        </Section>
        <BgImageWrapper>
          {/* <Img fixed={bottomImage.image.fixed} /> */}
          <div style={{ width: '100%', position: 'relative' }}>
            <Img fluid={bottomImage.image.fluid} />
          </div>
        </BgImageWrapper>
      </PageWrapper>
    )
  }
}

export default Invest

export const pageQuery = graphql`
  query {
    bottomImage: file(relativePath: { eq: "images/invest/invest-bg.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1920, height: 2362, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
// 1920 × 2362
